import { Filter } from '@/application/FilteredList/Filter'
import { ListBoulder } from '@/domain/boulder/ListBoulder'
import { category } from '@/domain/boulder/categories'
import { grades } from '@/domain/boulder/grades'
import { activeSectors } from '@/domain/sector'

export const CATEGORY = 'category'
export const GRADE = 'grade'
export const TOPPED = 'topped'
export const BOOKMARKED = 'bookmarked'
export const SECTOR = 'sector'
export const CREATED_BY = 'createdBy'
export const availableFilterTypes = [CATEGORY, GRADE, SECTOR, TOPPED, BOOKMARKED, CREATED_BY] as const

export type AvailableBoulderFilterTypes = typeof availableFilterTypes[number]

export const availableFilterLabels = {
    category: 'Kategorie',
    grade: 'Schwierigkeit',
    sector: 'Sektoren',
    topped: 'Topped',
    bookmarked: 'Bookmarked',
    createdBy: 'Artist'
}

interface AvailableFilters<T, K extends keyof T> {
    label: (type: K) => string
    emptyFilter: (type: K) => Filter<T, K>
    types: () => readonly K[]
    values: (type: K) => T[K][]
}

export class AvailableBoulderFilters implements AvailableFilters<ListBoulder, AvailableBoulderFilterTypes> {
    private readonly availableTypes: readonly AvailableBoulderFilterTypes[]
    private createdBy: string[]

    constructor(availableTypes: readonly AvailableBoulderFilterTypes[], createdBy: string[] = []) {
        this.availableTypes = availableTypes
        this.createdBy = createdBy
    }

    emptyFilter(type: AvailableBoulderFilterTypes): Filter<ListBoulder, AvailableBoulderFilterTypes> {
        return new Filter(type, [])
    }

    label(type: AvailableBoulderFilterTypes): string {
        return availableFilterLabels[type]
    }

    values(type: AvailableBoulderFilterTypes): ListBoulder[AvailableBoulderFilterTypes][] {
        if (type === CATEGORY) {
            return category.types
        }

        if (type === GRADE) {
            return grades.types
        }

        if (type === SECTOR) {
            return activeSectors.types
        }

        if (type === TOPPED || type === BOOKMARKED) {
            return [true, false]
        }

        if (type === CREATED_BY) {
            return this.createdBy
        }

        throw Error('filter type not available')
    }

    types(): readonly AvailableBoulderFilterTypes[] {
        return this.availableTypes
    }
}

export const availableBoulderFilters = new AvailableBoulderFilters(availableFilterTypes)
