


















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BoulderFilter } from '@/infrastructure/store/filter/types'
import { category } from '@/domain/boulder/categories'
import {
    availableBoulderFilters,
    AvailableBoulderFilterTypes,
    availableFilterLabels,
    CREATED_BY
} from '@/domain/filter/availableBoulderFilters'
import { grades } from '@/domain/boulder/grades'
import { bookmarkedText, toppedText } from '@/domain/boulder/UserSpecificDetails'
import { ListBoulder } from '@/domain/boulder/ListBoulder'
import { Filter } from '@/application/FilteredList/Filter'
import { activeSectors } from '@/domain/sector'

@Component
export default class FilterSelect extends Vue {
    @Prop({ type: Array, required: true }) filters!: BoulderFilter[]
    @Prop({ type: Array }) artists!: string[]

    get selectedArtists(): string[] {
        return this.artistFilter.values() as string[]
    }

    updateSelectedArtists(value: string[]): void {
        this.onChange(value, this.artistFilter.type())
    }

    filterItems(type: string): { value: string | boolean; text: string }[] {
        if (type === 'category') {
            return category.asItems()
        }

        if (type === 'grade') {
            return grades.asItems()
        }

        if (type === 'topped') {
            return [true, false].map((value) => ({ value: value, text: toppedText(value) }))
        }

        if (type === 'bookmarked') {
            return [true, false].map((value) => ({ value: value, text: bookmarkedText(value) }))
        }

        if (type === 'sector') {
            return activeSectors.items
        }

        return []
    }

    get artistFilter(): BoulderFilter {
        const artistFilter = this.filters.filter((f) => f.type() === CREATED_BY)

        if (artistFilter.length === 0) {
            return availableBoulderFilters.emptyFilter(CREATED_BY)
        }

        return artistFilter[0]
    }

    label(type: AvailableBoulderFilterTypes): string {
        return availableFilterLabels[type]
    }

    onChange(values: ListBoulder[keyof ListBoulder][], type: keyof ListBoulder): void {
        const updatedFilter: BoulderFilter = new Filter(type, values)
        this.$emit('update-filter', updatedFilter)
    }

    id(type: string): string {
        return `filter-type-${type}`
    }

    testId(type: string): string {
        return `filter-value-${type}`
    }
}
