
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { BoulderFilter } from '@/infrastructure/store/filter/types'
import {
    AvailableBoulderFilterTypes,
    BOOKMARKED,
    CATEGORY,
    CREATED_BY,
    GRADE,
    SECTOR,
    TOPPED
} from '@/domain/filter/availableBoulderFilters'
import { categoryText, CategoryType } from '@/domain/boulder/categories'
import { gradeText, GradeType } from '@/domain/boulder/grades'
import { bookmarkedText, toppedText } from '@/domain/boulder/UserSpecificDetails'
import { BoulderId } from '@/domain/boulder/Id'
import { activeSectors } from '@/domain/sector'
import { SectorId } from '@/domain/sector/sector-id'

export type ChipData = {
    text: string
    type: AvailableBoulderFilterTypes
    value: string | boolean | BoulderId | Date
}

@Component({})
export default class FilterChips extends Vue {
    @Prop({ required: true, type: Object }) filter!: BoulderFilter

    filterValues(): ChipData[] {
        const type = this.filter.type() as AvailableBoulderFilterTypes

        switch (type) {
            case BOOKMARKED:
                return this.filter.values().map((value) => ({
                    text: bookmarkedText(!!value),
                    type: BOOKMARKED,
                    value
                }))

            case TOPPED:
                return this.filter.values().map((value) => ({
                    text: toppedText(!!value),
                    type: TOPPED,
                    value
                }))

            case CATEGORY:
                return this.filter.values().map((value) => {
                    const category = value as CategoryType
                    return {
                        text: categoryText[category],
                        type: CATEGORY,
                        value
                    }
                })

            case GRADE:
                return this.filter.values().map((value) => {
                    const grade = value as GradeType
                    return {
                        text: gradeText[grade],
                        type: GRADE,
                        value
                    }
                })

            case SECTOR:
                return this.filter.values().map((value) => {
                    const sectorId = value as string
                    return {
                        text: activeSectors.textById(SectorId.fromString(sectorId)),
                        type: SECTOR,
                        value
                    }
                })

            case CREATED_BY:
                return this.filter.values().map((value) => {
                    const name = value as string
                    return {
                        text: name,
                        type: CREATED_BY,
                        value
                    }
                })
        }
    }

    testId(): string {
        return `filter-chip-${this.filter.type()}`
    }

    onClick(value: ChipData): void {
        this.$emit('remove-value', value)
    }
}
