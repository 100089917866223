




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { BoulderFilter } from '@/infrastructure/store/filter/types'
import FilterChips, { ChipData } from '@/components/boulder/filter/filter-chips.vue'
import FilterSelect from '@/components/boulder/filter/filter-select/filter-select.vue'
import { availableBoulderFilters, AvailableBoulderFilterTypes } from '@/domain/filter/availableBoulderFilters'
import { Filter } from '@/application/FilteredList/Filter'
import { ListBoulder } from '@/domain/boulder/ListBoulder'

@Component({
    components: { FilterSelect, FilterChips }
})
export default class FilterBoulders extends Vue {
    @Prop({ required: true, type: Map }) selectedFiltersMap!: Map<AvailableBoulderFilterTypes, BoulderFilter>
    @Prop({ required: true, type: Boolean }) open!: false
    @Prop({ type: Array }) artists!: string[]

    get filters(): BoulderFilter[] {
        return availableBoulderFilters.types().map((type) => {
            const filter = this.selectedFiltersMap.get(type)
            if (!filter) {
                return availableBoulderFilters.emptyFilter(type)
            }

            return filter
        })
    }

    selectedFilters(): IterableIterator<BoulderFilter> {
        return this.selectedFiltersMap.values()
    }

    onUpdateFilter(payload: BoulderFilter): void {
        this.$emit('update-filter', payload)
    }

    onRemoveValue(chipData: ChipData): void {
        const currentFilter = this.selectedFiltersMap.get(chipData.type)
        if (!currentFilter) {
            console.warn('could not find filter: ', chipData.type)
            return
        }

        const updatedValues = currentFilter
            .values()
            .filter((value) => value !== chipData.value) as ListBoulder[AvailableBoulderFilterTypes][]
        const updatedFilter: BoulderFilter = new Filter(chipData.type, updatedValues)
        this.onUpdateFilter(updatedFilter)
    }
}
